@import url(https://fonts.googleapis.com/css2?family=Teko:wght@500&family=Poppins:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Cabin+Sketch&family=Fredericka+the+Great&family=Indie+Flower&family=Kalam:wght@300&display=swap);
:root {
  --x-red: #e60909;
  --x-heading-size: 3.2em;
}
@media (max-width: 1024px) {
  :root{
    --x-heading-size: 2.3em;
  }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  scroll-behavior: smooth;
}

#caseBlanche {
  background-color: black;

  padding-top: 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.caseLoad {
  max-width: 100%;
  width: 20em;
  height: 20em;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: visible;
}
.img-load {
  -webkit-animation: bouncy 2s linear infinite;
          animation: bouncy 2s linear infinite;
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  z-index: 100;
}
.img-load > img {
  position: relative;
  width: 100%;
  height: auto;
  object-fit: contain;
}
#rond {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-animation: rond 2s linear infinite;
          animation: rond 2s linear infinite;
  border: 1px solid white;
}
#rond > #test {
  height: 8px;
  width: 8px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  left: 40%;
}
@keyframes rond {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes rond {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes bouncy {
  0% {
    -webkit-transform: translateX(-50%) translateY(-100%);
            transform: translateX(-50%) translateY(-100%);
  }

  50% {
    -webkit-transform: translateX(-50%) translateY(50%);
            transform: translateX(-50%) translateY(50%);
  }

  100% {
    -webkit-transform: translateX(-50%) translateY(-100%);
            transform: translateX(-50%) translateY(-100%);
  }
}

@keyframes bouncy {
  0% {
    -webkit-transform: translateX(-50%) translateY(-100%);
            transform: translateX(-50%) translateY(-100%);
  }

  50% {
    -webkit-transform: translateX(-50%) translateY(50%);
            transform: translateX(-50%) translateY(50%);
  }

  100% {
    -webkit-transform: translateX(-50%) translateY(-100%);
            transform: translateX(-50%) translateY(-100%);
  }
}

.App {
  text-align: center;
  overflow-x: hidden;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.discordCont {
  position: fixed;
  bottom: 10px;
  right: 10px;
  -webkit-animation: comein 2s linear;
          animation: comein 2s linear;
}
@-webkit-keyframes comein {
  0% {
    bottom: -200px;
  }
  20% {
    bottom: 10px;
  }
  30% {
    right: 12px;
  }
  40% {
    right: 7px;
  }
  50% {
    right: 12px;
  }
  60% {
    right: 12px;
  }
  70% {
    right: 7px;
  }
  80% {
    right: 12px;
  }
  90% {
    right: 7px;
  }
  100% {
    right: 10px;
  }
}
@keyframes comein {
  0% {
    bottom: -200px;
  }
  20% {
    bottom: 10px;
  }
  30% {
    right: 12px;
  }
  40% {
    right: 7px;
  }
  50% {
    right: 12px;
  }
  60% {
    right: 12px;
  }
  70% {
    right: 7px;
  }
  80% {
    right: 12px;
  }
  90% {
    right: 7px;
  }
  100% {
    right: 10px;
  }
}
@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.back {
  background-image: "spot3.png";
  background-color: #000;
  min-height: 100vh;
}
.clear {
  clear: both;
}

.layer {
  width: 100%;
}

.maskPhoto {
  height: 60vh;
  margin-left: 8%;
  top: 320px;
  float: left;
  object-fit: contain;
}
.material {
  color: antiquewhite;
  margin-left: 0%;
  margin-top: 3.5%;
  display: block;
  font-family: Poppins;
  text-align: start;
  font-style: normal;
  font-weight: normal;
  flex-wrap: wrap;
  line-height: 25px;
  margin-left: 20%;
  color: #ffffff;
  float: left;
}
.headingAbout {
  border-bottom: 5px solid var(--x-red);
  font-family: PurplePurse;
  font-style: normal;
  font-weight: bolder;
  font-size: var(--x-heading-size);
  line-height: 90px;
  color: #ffffff;
  max-width: -webkit-max-content;
  max-width: max-content;
  text-shadow: 0.4rem 1rem 0.1rem rgba(0, 0, 0, 0.25);
  margin: auto;
}
.containerAbout {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 80px 50px;
  /* width: 50%;
  float: right;
  margin-right: 8%; */
}

@media (max-width: 1024px) {
  .maskPhoto {
    width: 80vw;
    margin-left: 0;
    float: center;
    opacity: 50%;
  }
  .material {
    color: antiquewhite;
    margin-top: 3.5%;
    display: block;
    font-family: Poppins;
    text-align: start;
    font-style: normal;
    font-weight: normal;
    flex-wrap: wrap;
    line-height: 25px;
    margin-left: 0;
    color: #ffffff;
    float: left;
    padding-bottom: 30px;
  }

  .headingAbout {
    border-bottom: 5px solid var(--x-red);
    font-family: PurplePurse;
    font-style: normal;
    font-weight: bolder;
    font-size: 2.3em;
    color: #ffffff;
    max-width: -webkit-max-content;
    max-width: max-content;
    text-shadow: 0.4rem 1rem 0.1rem rgba(0, 0, 0, 0.25);
    margin: 0;
    margin-bottom: 20px;
  }
  .containerAbout {
    align-content: center;
    text-align: center;
    align-items: center;
    float: unset;
    display: block;
    margin: auto;
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .maskPhoto {
    width: 100vw;
    float: right;
    opacity: 50%;
    display: none;
  }
  .material {
    color: antiquewhite;
    margin-top: 3.5%;
    display: block;
    font-family: Poppins;
    text-align: start;
    font-style: normal;
    font-weight: normal;
    flex-wrap: wrap;
    line-height: 25px;
    margin-left: 0;
    color: #ffffff;
    float: left;
    padding-bottom: 30px;
  }

  .headingAbout {
    border-bottom: 5px solid var(--x-red);
    font-family: PurplePurse;
    font-style: normal;
    font-weight: bolder;
    font-size: 2.3em;
    color: #ffffff;
    max-width: -webkit-max-content;
    max-width: max-content;
    text-shadow: 0.4rem 1rem 0.1rem rgba(0, 0, 0, 0.25);
    margin: 0;
    margin-bottom: 20px;
  }
  .containerAbout {
    align-content: center;
    text-align: center;
    align-items: center;
    float: unset;
    display: block;
    margin: auto;
    flex-wrap: wrap;
  }
}

@-webkit-keyframes blinx {
  0%,
  50%,
  100% {
    box-shadow: 5px 5px 20px #ff1919a8;
  }
  25%,
  75% {
    box-shadow: 5px 5px 10px #00000073, 0 30px 20px -10px #00000021;
  }
}
@keyframes blinx {
  0%,
  50%,
  100% {
    box-shadow: 5px 5px 20px #ff1919a8;
  }
  25%,
  75% {
    box-shadow: 5px 5px 10px #00000073, 0 30px 20px -10px #00000021;
  }
}

@-webkit-keyframes imgx {
  0%,
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  20%,
  80% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    opacity: 0.8;
  }
  30%,
  70% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    opacity: 0.6;
  }
  40%,
  60% {
    -webkit-transform: scale(1.14);
    transform: scale(1.14);
    opacity: 0.5;
  }
  50% {
    opacity: 0.4;
  }
}

@keyframes imgx {
  0%,
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  20%,
  80% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    opacity: 0.8;
  }
  30%,
  70% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    opacity: 0.6;
  }
  40%,
  60% {
    -webkit-transform: scale(1.14);
    transform: scale(1.14);
    opacity: 0.5;
  }
  50% {
    opacity: 0.4;
  }
}

@-webkit-keyframes bgx {
  0%,
  50%,
  100% {
    background-position: 30% 50%;
  }
  25%,
  75% {
    background-position: 70% 50%;
  }
}

@keyframes bgx {
  0%,
  50%,
  100% {
    background-position: 30% 50%;
  }
  25%,
  75% {
    background-position: 70% 50%;
  }
}

.mh_tx {
  font-family: "Teko", sans-serif;
  color: #fff;
  display: flex;
  font-size: 35px;
  padding: 5px;
  line-height: 1;
  text-shadow: 1px 1px 15px #000000f1;
}

.mh_tx > div.trans {
  background-color: #000;
  padding: 0 5px;
}

.mh_tx > div.red {
  border-radius: 2px;
  background-color: var(--x-red);
  color: #000;
  padding: 0px 5px;
}

.s_parent {
  background-color: #000;
  background-image: linear-gradient(
    45deg,
    #000 40%,
    rgba(0, 0, 0, 0.609),
    #aaaaaa3d,
    rgba(0, 0, 0, 0.609),
    #000 60%
  );
  background-size: 400% 400%;
  -webkit-animation: bgx 20s ease infinite;
  animation: bgx 20s ease infinite;
  overflow: hidden;
}

.s_parent .s_me .s_big_child,
.s_parent .s_me .s_small_child {
  display: flex;
  justify-content: space-between;
}

.s_parent .s_me .s_big_child > img,
.s_parent .s_me .s_small_child > img {
  -webkit-animation: imgx 2s linear infinite;
  animation: imgx 2s linear infinite;
}

.s_parent .s_me .s_big_child .s_name {
  border-bottom: 5px solid var(--x-red);
  font-family: PurplePurse;
  font-style: normal;
  font-weight: bolder;
  font-size: var(--x-heading-size);
  text-transform: uppercase;
  line-height: 90px;
  color: #ffffff;
  text-shadow: 0.4rem 1rem 0.1rem rgba(0, 0, 0, 0.25);
  -ms-grid-row-align: center;
  align-self: center;
}

.s_parent .s_me .s_small_child .s_name {
  font-size: 2rem;
  font-family: "Teko", sans-serif;
  color: #fff;
  text-shadow: 5px 15px 5px #000000be;
  -ms-grid-row-align: center;
  align-self: center;
}

.s_parent .s_me .s_child {
  display: flex;
}

.s_parent .s_me .s_child .planx {
  width: 80%;
  max-width: 300px;
  border-radius: 5px;
  border: 1px solid var(--x-red);
  box-shadow: 5px 5px 10px #00000073, 0 30px 20px -10px #00000021;
  cursor: default;
  transition: 0.8s;
}

.s_parent .s_me .s_child .planx:hover {
  background-color: #00000050;
  background-image: linear-gradient(to top, #000000, #2a3034);
  border-color: #fff;
  -webkit-animation: blinx 2s linear infinite;
  animation: blinx 2s linear infinite;
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.s_parent .s_me .s_child .planx:hover .p_heading > p {
  color: #ff3131;
}

.s_parent .s_me .s_child .planx .p_heading {
  margin-top: -20px;
}

.s_parent .s_me .s_child .planx .p_heading > p {
  color: #ccc;
  padding: 10px;
  font-size: 0.9rem;
  font-family: "Poppins", sans-serif;
  text-align: left;
}

.s_parent .s_me .s_child .child_l {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.s_parent .s_me .s_child .child_l .planx.left.right {
  display: none;
}

.s_parent .s_me .s_child .nairobi {
  -ms-grid-row-align: center;
  align-self: center;
  width: 20%;
}

.s_parent .s_me .s_child .child_r {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}

@media screen and (max-width: 960px) {
  .s_parent .s_me .s_child .planx.left {
    float: right;
  }
  .s_parent .s_me .s_child .planx.right {
    float: left;
  }
  .s_parent .s_me .s_child .child_l {
    display: block;
  }
  .s_parent .s_me .s_child .child_l > .planx {
    margin-bottom: 20%;
  }
  .s_parent .s_me .s_child .child_r {
    display: block;
  }
  .s_parent .s_me .s_child .child_r > .planx {
    margin-top: 20%;
  }

  .s_parent .s_me .s_big_child .s_name {
    border-bottom: 5px solid var(--x-red);
    font-family: PurplePurse;
    font-style: normal;
    font-weight: bolder;
    font-size: 2.6em;
    line-height: 90px;
    color: #ffffff;
    text-shadow: 0.4rem 1rem 0.1rem rgba(0, 0, 0, 0.25);
    /* -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center; */

    margin: 0 auto;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 720px) {
  .s_parent .s_me .s_child .child_l {
    width: 60%;
  }
  .s_parent .s_me .s_child .child_l .planx.right.left {
    margin-left: 10px;
    display: block;
    float: left;
  }
  .s_parent .s_me .s_child .child_l > .planx {
    margin-bottom: 10%;
  }
  .s_parent .s_me .s_child .nairobi {
    width: 40%;
  }
  .s_parent .s_me .s_child .child_r {
    display: none;
  }
}

@media screen and (max-width: 420px) {
  .s_parent .s_me .s_child {
    justify-content: center;
  }
  .s_parent .s_me .s_child .child_l {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .s_parent .s_me .s_child .child_l .planx.left {
    margin-right: 10px;
    -ms-grid-row-align: flex-end;
    align-self: flex-end;
  }
  .s_parent .s_me .s_child .child_l .planx.right.left {
    margin-left: 10px;
    -ms-grid-row-align: flex-start;
    align-self: flex-start;
  }
  .s_parent .s_me .s_child .nairobi {
    display: none;
  }
}

@-webkit-keyframes bgCircle {
  from {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
  }
}
@keyframes bgCircle {
  from {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
  }
}

@-webkit-keyframes proToggle {
  0% {
    right: 0px;
  }
  25%,
  50%,
  75% {
    right: 10px;
  }
  100% {
    right: 0px;
  }
}

@keyframes proToggle {
  0% {
    right: 0px;
  }
  25%,
  50%,
  75% {
    right: 10px;
  }
  100% {
    right: 0px;
  }
}

.faq_parent {
  background: #000;
  min-height: 100vh;
  color: #ccc;
  z-index: 0;
}

.faq_parent ::-webkit-scrollbar {
  width: 5px;
}

.faq_parent ::-webkit-scrollbar-track {
  background: #111111b4;
}

.faq_parent ::-webkit-scrollbar-thumb {
  background: var(--x-red);
}

.faq_parent ::-webkit-scrollbar-thumb:hover {
  background: var(--x-red);
}

.faq_parent .faq_bg_circle {
  width: 7vw;
  height: 7vw;
  position: absolute;
  z-index: 1;
  border-radius: 50%;
  background-image: radial-gradient(#fdfdfd1c, #faf9f91e, #fffbfb1a);
  -webkit-filter: blur(8px);
          filter: blur(8px);
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-animation: bgCircle 10s infinite;
  animation: bgCircle 10s infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.faq_parent .faq_blur_bg {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  z-index: 2;
}

.faq_parent .faq_me {
  z-index: 3;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.faq_parent .faq_me .faq_heading {
  margin: 3% 0;
  border-bottom: 5px solid var(--x-red);
  font-family: PurplePurse;
  font-style: normal;
  font-weight: bolder;
  font-size: var(--x-heading-size);
  line-height: 90px;
  color: #ffffff;
  text-shadow: 0.4rem 1rem 0.1rem rgba(0, 0, 0, 0.25);
}

.faq_parent .faq_me .faq_container {
  border-radius: 25px;
  width: 90%;
  max-width: 1024px;
  min-height: 400px;
  background-color: rgba(26, 31, 34, 0.8);
  border: 1px solid #444444;
  box-shadow: 20px 20px 20px #0c0c0c;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.faq_parent .faq_me .faq_container .faq_qns,
.faq_parent .faq_me .faq_container .faq_not_qns {
  height: 100%;
  width: 50%;
}

.faq_parent .faq_me .faq_container .faq_qns {
  padding: 30px;
  overflow-y: auto;
}

.faq_parent .faq_me .faq_container .faq_qns .faq_q {
  width: 100%;
  font-family: sans-serif;
  border: 1px solid var(--x-red);
  background-color: #000;
  border-radius: 10px;
  padding: 15px;
  margin-top: 10px;
  cursor: pointer;
  transition: 0.5s;
}

.faq_parent .faq_me .faq_container .faq_qns .faq_q:hover {
  -webkit-transform: translateX(10px);
  transform: translateX(10px);
  background-color: #000;
  box-shadow: -5px -5px 15px #000;
}

.faq_parent .faq_me .faq_container .faq_qns .faq_q:hover::before {
  content: ">> ";
}

.faq_parent .faq_me .faq_container .faq_not_qns {
  padding: 2.5% 5%;
  display: flex;
  align-items: center;
}

.faq_parent .faq_me .faq_container .faq_not_qns > img {
  width: 90%;
}

.faq_parent .faq_ans_screen {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background: #23232371;
  background-image: linear-gradient(0deg, #0f0e0e71, #000000d0),
    url("https://wallpaperaccess.com/full/2626507.jpg");
  z-index: 100;
  transition: 0.8s;
}

.faq_parent .faq_ans_screen .faq_ans_board {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 95%;
  height: 90%;
  overflow-y: auto;
  overflow-x: hidden;
  background: #0c130c;
  background-image: linear-gradient(0deg, #0f0e0e34, #00000046),
    url("https://images.unsplash.com/photo-1578662996442-48f60103fc96?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YmxhY2tib2FyZHxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&w=1000&q=80");
  z-index: 110;
  border: 10px solid #141414;
}

.faq_parent .faq_ans_screen .faq_ans_board .board_question {
  padding: 20px;
  font-size: 2rem;
  text-align: left;
  margin-bottom: 20px;
  font-family: "Fredericka the Great", cursive;
}

.faq_parent .faq_ans_screen .faq_ans_board .board_answer {
  padding: 20px;
  font-size: 1.3rem;
  text-align: left;
  font-family: "Kalam", cursive;
}

.faq_parent .faq_ans_screen .faq_ans_board .board_answer > p {
  text-indent: 20px;
}

.faq_parent .faq_ans_screen .faq_ans_professor {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 111;
}

.faq_parent .faq_ans_screen .faq_ans_professor > img {
  width: 70%;
  transition: 0.8s;
}

.faq_parent .faq_ans_screen .faq_ans_professor .professor_toggle {
  right: 10px;
  bottom: 10px;
  cursor: pointer;
  -webkit-animation: proToggle 2s infinite;
  animation: proToggle 2s infinite;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

@media (max-width: 720px) {
  .faq_parent .faq_me .faq_container .faq_qns {
    width: 100%;
  }
  .faq_parent .faq_me .faq_container .faq_not_qns {
    display: none;
  }

  .faq_container {
    margin-top: 10px;
    margin-bottom: 40px;
  }
}

.building {
  width: 100%;
}
.Trackx {
  width: 100%;
  min-height: 100vh;
  background-color: #000;
  background-image: url(/static/media/bank.2fca360b.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  padding: 20px 0;
}
.Trackx .left {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 20%;
}
.Trackx .Right {
  position: absolute;
  width: 20%;
  top: 0%;
  right: 0%;
}

.Trackx .track_box {
  padding: 5%;
}

.Trackx .track_box .box_body {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: #fff;
  font-family: Poppins;
  font-style: normal;
  text-shadow: 0.4rem 1rem 0.1rem rgba(0, 0, 0, 0.25);
}
.Trackx .track_box .box_body .box {
  width: 50%;
  min-width: 300px;
  padding: 20px 5px;
}

.Trackx .track_box .box_body .box:hover {
  background-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.74),
    rgba(22, 1, 1, 0.767)
  );
  text-shadow: 0.4rem 1rem 0.1rem rgba(255, 255, 255, 0.048);
}
.Trackx .track_box .box_body .Agriculture,
.Trackx .track_box .box_body .Communication {
  border-bottom: 2px solid #abafb0;
  align-self: flex-end;
}

.Trackx .track_box .box_body .Healthcare,
.Trackx .track_box .box_body .Communication {
  border-right: 2px solid #abafb0;
}

.Trackx .track_box .box_body .Open {
  align-self: flex-end;
  border-top: 2px solid #abafb0;
  margin: auto;
}

.Trackx .Tracks > p {
  border-bottom: 5px solid var(--x-red);
  font-family: PurplePurse;
  font-style: normal;
  font-weight: bolder;
  font-size: var(--x-heading-size);
  text-transform: uppercase;
  color: #ffffff;
  text-shadow: 0.4rem 1rem 0.1rem rgba(0, 0, 0, 0.25);
  width: -webkit-max-content;
  width: max-content;
  margin: 0 auto;
}

@media (max-width: 700px) {
  .Trackx {
    width: 100%;
    min-height: 100vh;
    background-color: #000;
    background-image: none;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    padding: 20px 0;
  }

  .Trackx .track_box .box_body {
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
  }
  .Trackx .track_box .box_body .Agriculture,
  .Trackx .track_box .box_body .Communication {
    align-self: center;
  }
  .Trackx .track_box .box_body .box {
    width: 80%;
    border-right: none;
    border-bottom: 2px solid #abafb0;
  }
}

.Prizes {
  min-height: 100vh;
  max-height: -webkit-max-content;
  max-height: max-content;
  background-color: #000;
  z-index: 0;
}

.Prizes .h_name {
  border-bottom: 5px solid var(--x-red);
  font-family: PurplePurse;
  font-style: normal;
  font-weight: bolder;
  font-size: var(--x-heading-size);
  text-transform: uppercase;
  line-height: 90px;
  color: #ffffff;
  text-shadow: 0.4rem 1rem 0.1rem rgba(0, 0, 0, 0.25);
  width: -webkit-max-content;
  width: max-content;
  margin: auto;
}

.PrizesContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 90vh;
  justify-content: space-around;
  align-items: center;
  background: url("/static/media/Money-Heist-Mask-PNG-Image 3.7f750e15.svg");
  background-color: #000;
  background-position: center;
  background-repeat: repeat-x;
}

.Prizes .PrizesContainer .cont1 img {
  width: 500px;
  height: 500px;
  object-fit: contain;
}

@media (max-width: 768px) {
  .Prizes {
    min-height: -webkit-max-content;
    min-height: max-content;
  }
  .PrizesContainer {
    background-repeat: no-repeat;
  }

  .Prizes .PrizesContainer .cont1 img {
    padding-left: 25px;
    width: 100vw;
    height: 50vh;
    object-fit: contain;
  }
}

@-webkit-keyframes simgx {
  0%,
  100% {
    -webkit-transform: scale(1) rotate(0deg);
    transform: scale(1) rotate(0deg);
    opacity: 1;
  }
  20%,
  80% {
    -webkit-transform: scale(1.05) rotate(45deg);
    transform: scale(1.05) rotate(45deg);
    opacity: 0.8;
  }
  30%,
  70% {
    -webkit-transform: scale(1.1) rotate(90deg);
    transform: scale(1.1) rotate(90deg);
    opacity: 0.6;
  }
  40%,
  60% {
    -webkit-transform: scale(1.14) rotate(135deg);
    transform: scale(1.14) rotate(135deg);
    opacity: 0.5;
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    opacity: 0.4;
  }
}

@keyframes simgx {
  0%,
  100% {
    -webkit-transform: scale(1) rotate(0deg);
    transform: scale(1) rotate(0deg);
    opacity: 1;
  }
  20%,
  80% {
    -webkit-transform: scale(1.05) rotate(45deg);
    transform: scale(1.05) rotate(45deg);
    opacity: 0.8;
  }
  30%,
  70% {
    -webkit-transform: scale(1.1) rotate(90deg);
    transform: scale(1.1) rotate(90deg);
    opacity: 0.6;
  }
  40%,
  60% {
    -webkit-transform: scale(1.14) rotate(135deg);
    transform: scale(1.14) rotate(135deg);
    opacity: 0.5;
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    opacity: 0.4;
  }
}

@-webkit-keyframes danger_blinx {
  0%,
  50%,
  100% {
    box-shadow: 5px 5px 100px #f00;
  }
  25%,
  75% {
    box-shadow: 5px 5px 10px #00000073, 0 30px 20px -10px #00000021;
  }
}

@keyframes danger_blinx {
  0%,
  50%,
  100% {
    box-shadow: 5px 5px 100px #f00;
  }
  25%,
  75% {
    box-shadow: 5px 5px 10px #00000073, 0 30px 20px -10px #00000021;
  }
}

@-webkit-keyframes spox_move {
  0%,
  100%,
  20%,
  80% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  10%,
  90% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  33%,
  67% {
    -webkit-transform: translateY(-10px) rotateY(0deg);
    transform: translateY(-10px) rotateY(0deg);
  }
  50% {
    -webkit-transform: translateY(-10px) rotateY(180deg);
    transform: translateY(-10px) rotateY(180deg);
  }
}

@keyframes spox_move {
  0%,
  100%,
  20%,
  80% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  10%,
  90% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  33%,
  67% {
    -webkit-transform: translateY(-10px) rotateY(0deg);
    transform: translateY(-10px) rotateY(0deg);
  }
  50% {
    -webkit-transform: translateY(-10px) rotateY(180deg);
    transform: translateY(-10px) rotateY(180deg);
  }
}

.spox {
  max-width: 300px;
  width: 50vw;
  min-width: 140px;
  height: 135px;
  background: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  border: 2px solid #e60909;
  cursor: default;
  transition: 0.5s;
  margin: 0 5px;
}

.spox:hover {
  background: #0000;
  border-color: #0000;
}

.spox:hover > .spox_logo > img {
  background: #ffffff;
  border-radius: 50%;
  -webkit-animation: spox_move 10s linear infinite;
  animation: spox_move 10s linear infinite;
  box-shadow: 0px 20px 5px -10px #000;
}

.spox > .spox_logo > img {
  background: #fcfcfc94;
  border-radius: 50%;
}

.spox > .spox_name {
  font-family: PurplePurse;
  letter-spacing: 1px;
  font-size: 1.2rem;
  color: #ffffff;
  font-weight: 500;
  text-shadow: 3px 3px 2px #000;
}

.sp_parent {
  background-color: #000;
  background-image: linear-gradient(
    45deg,
    #000 40%,
    rgba(0, 0, 0, 0.609),
    #aaaaaa3d,
    rgba(0, 0, 0, 0.609),
    #000 60%
  );
  color: #fff;
  -webkit-animation: bgx 20s linear infinite;
  animation: bgx 20s linear infinite;
  background-size: 400% 400%;
  overflow: hidden;
}

.sp_parent .sp_me .sp_big_child,
.sp_parent .sp_me .sp_small_child {
  height: 30vh;
  display: flex;
  justify-content: space-around;
  overflow: hidden;
}

.sp_parent .sp_me .sp_big_child .sp_name,
.sp_parent .sp_me .sp_small_child .sp_name {
  border-bottom: 5px solid #e60909;
  font-family: PurplePurse;
  font-style: normal;
  font-weight: bolder;
  font-size: 3.2em;
  line-height: 90px;
  color: #ffffff;
  text-shadow: 0.4rem 1rem 0.1rem rgba(0, 0, 0, 0.25);
  -ms-grid-row-align: center;
  align-self: center;
}

.sp_parent .sp_me .sp_big_child > img,
.sp_parent .sp_me .sp_small_child > img {
  -webkit-transform: translateY(-200%);
  transform: translateY(-200%);
  transition: 1s;
  opacity: 0.8;
}

.sp_parent .sp_me .sp_big_child:hover > img,
.sp_parent .sp_me .sp_small_child:hover > img {
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
}

.sp_parent .sp_me .sp_child {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sp_parent .sp_me .sp_child .sp_child_fav_bag {
  width: 90vw;
  max-width: 800px;
  border-radius: 0 0 5px 5px;
  box-shadow: 5px 5px 50px #000;
  background: #0000;
}

.sp_parent .sp_me .sp_child .sp_child_fav_bag:hover {
  border-color: #0000 #e60909 #e60909 #e60909;
  -webkit-animation: danger_blinx 2.6s linear infinite;
  animation: danger_blinx 2.6s linear infinite;
}

.sp_parent .sp_me .sp_child .sp_child_fav_bag .sp_child_fav_bag_zip_even {
  display: flex;
  padding: 20px;
  justify-content: space-around;
}

.sp_parent .sp_me .sp_child .sp_child_fav_bag .sp_child_fav_bag_zip_odd {
  display: flex;
  padding: 20px;
  justify-content: space-evenly;
}

@media screen and (max-width: 768px) {
  .sp_parent .sp_me .sp_big_child,
  .sp_parent .sp_me .sp_small_child {
    align-items: center;
  }
  .sp_parent .sp_me .sp_big_child .sp_name,
  .sp_parent .sp_me .sp_small_child .sp_name {
    border-bottom: 5px solid #e60909;
    font-family: PurplePurse;
    font-style: normal;
    font-weight: bolder;
    font-size: 2.7em;
    line-height: 60px;
    color: #ffffff;
    text-shadow: 0.4rem 1rem 0.1rem rgba(0, 0, 0, 0.25);
    -ms-grid-row-align: center;
    align-self: center;
  }
}
@media screen and (max-width: 720px) {
  .sp_parent .sp_me .sp_big_child,
  .sp_parent .sp_me .sp_small_child {
    align-items: center;
  }
}

.navbar {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  padding: 7px 40px;
  margin: 0;
  z-index: 100;
}
.logo {
  width: 100px;
  object-fit: contain;
  -webkit-filter: drop-shadow(0px 0px 10px whitesmoke);
          filter: drop-shadow(0px 0px 10px whitesmoke);
}

.burger div {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 5px;
  transition: 0.5s ease all;
  display: none;
  z-index: 110;
}

.toggle .line1 {
  -webkit-transform: rotate(-45deg) translate(-5px, 6px);
          transform: rotate(-45deg) translate(-5px, 6px);
}
.toggle .line2 {
  opacity: 0;
}
.toggle .line3 {
  -webkit-transform: rotate(45deg) translate(-5px, -6px);
          transform: rotate(45deg) translate(-5px, -6px);
}

.list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1 1;
}

.list ul {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.list ul li {
  padding: 0px 30px;
  font-size: bold;
  font-weight: 700;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  list-style: none;
  text-decoration: none;
  position: relative;
}
.list ul li a {
  text-decoration: none;
  list-style: none;
}
.list ul li span {
  padding: 2px;
  font-size: 1.2 rem;
  color: white;
  position: relative;
  transition: all 300ms ease-in-out;
  border-bottom: 3px solid transparent !important;
}
.list ul li span.borderRed {
  border-bottom: 3px solid red !important;
  -webkit-filter: drop-shadow(0px 0px 10px whitesmoke);
          filter: drop-shadow(0px 0px 10px whitesmoke);
  transition: all 300ms ease-in-out;
}
@media (max-width: 768px) {
  .navbar {
    padding: 7px 0px;
    flex-direction: row;
    font-size: 1rem;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }

  .list ul li {
    padding: 0px;
  }
  .list {
    display: flex;
    width: 100%;
    margin-top: 10px;
  }
  .list ul {
    justify-content: space-around;
    width: 100%;
    margin: 0;
    padding: 0;
  }
}

@media (max-width: 550px) {
  .burger {
    position: absolute;
    right: 20px;
    top: 30px;
    z-index: 102;
  }

  .list {
    position: absolute;
    flex-direction: column;
    top: 0;
    right: 0;
    bottom: 0%;
    width: 50vw;
    background-color: rgba(0, 0, 0, 0.26);
    height: 100vh;
    padding: 100px 0px;
    z-index: 50;
    -webkit-backdrop-filter: blur(1rem);
            backdrop-filter: blur(1rem);
    -webkit-transform: translateX(100%) translateY(-10px);
            transform: translateX(100%) translateY(-10px);
    transition: 0.5s ease-in;
  }

  .toggleSidebar {
    -webkit-transform: translateX(0%) translateY(-10px);
            transform: translateX(0%) translateY(-10px);
  }

  .list ul {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .list ul li {
    padding: 0px 30px;
    font-size: bold;
    font-weight: 700;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    list-style: none;
    text-decoration: none;
    position: relative;
  }
  .list ul li a {
    text-decoration: none;
    list-style: none;
  }
  .list ul li span {
    padding: 2px;
    font-size: 1.2 rem;
    color: white;
    position: relative;
    transition: all 300ms ease-in-out;
    border-bottom: 3px solid transparent !important;
  }
  .list ul li span.borderRed {
    border-bottom: 3px solid red !important;
    -webkit-filter: drop-shadow(0px 0px 10px whitesmoke);
            filter: drop-shadow(0px 0px 10px whitesmoke);
    transition: all 300ms ease-in-out;
  }
  .burger div {
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 5px;
    transition: 0.5s ease all;
    display: block;
  }

  .toggle .line1 {
    -webkit-transform: rotate(-45deg) translate(-5px, 6px);
            transform: rotate(-45deg) translate(-5px, 6px);
  }
  .toggle .line2 {
    opacity: 0;
  }
  .toggle .line3 {
    -webkit-transform: rotate(45deg) translate(-5px, -6px);
            transform: rotate(45deg) translate(-5px, -6px);
  }
}

.ABC {
  position: relative;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: black;
  box-sizing: content-box;
}
.Nairobi {
  max-height: 65vh;
  position: absolute;
  bottom: 29%;
  right: 0;
  z-index: 1;
}

.wave {
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  width: 100%;
}

.heading {
  color: rgb(255, 255, 255);
  font-weight: bold;
  text-align: left;
  padding: 100px 0px 0px 50px;
  box-sizing: content-box;
}
.heading p {
  font-family: PurplePurse;
  font-style: normal;
  font-weight: bolder;
  font-size: var(--x-heading-size);
  line-height: 90px;
  color: #ffffff;
  max-width: -webkit-max-content;
  max-width: max-content;
  text-shadow: 0.4rem 1rem 0.1rem rgba(0, 0, 0, 0.25);
}

.footerContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 4;
  width: 100vw;
  max-height: 100vh;
}

.footerContent .left {
  width: 500px;
  color: white;
}

.footerContent .left .RClogo {
  width: 300px;
  object-fit: contain;
}

.footerContent .right {
  color: #fff;
}
.footerContent .right ul {
  color: #fff;
  text-align: left;
  list-style: none;
}
#button {
  margin-top: 15px;
  border: 2px solid transparent;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  transition: all 0.3s linear;
  padding-block: 0.3em;
  background-color: var(--x-red);
  color: white;
  min-width: 12em;
}
#button:hover {
  opacity: 0.8;
}

@media (max-width: 1024px) {
  .ABC {
    min-height: 50vh;
  }

  .Nairobi {
    max-height: 40vh;
    position: absolute;
    bottom: 20%;
    right: 0;
    z-index: 1;
  }
}

@media (max-width: 768px) {
  .ABC {
    display: flex;
    min-height: 50vh;
    max-height: 100vh;
    flex-direction: column;
    justify-content: space-around;
  }
  .heading {
    color: rgb(255, 255, 255);
    font-weight: bold;
    text-align: left;
    padding: 0;
    -webkit-transform: translateY(-200px);
            transform: translateY(-200px);
    box-sizing: content-box;
    padding-left: 50px;
  }
  .footerContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 4;
    width: 100vw;
    max-height: 100vh;
  }
  .Nairobi {
    max-height: 40vh;
    position: absolute;
    bottom: 20%;
    right: 0;
    z-index: 1;
  }

  .footerContent .left {
    width: 45vw;
    color: white;
  }

  .footerContent .left .RClogo {
    width: 250px;
    object-fit: contain;
  }

  .footerContent .right {
    color: #fff;
    width: 45vw;
    align-items: center;
    text-align: center;
  }
  .footerContent .right ul li {
    color: #fff;
    align-items: center;
    text-align: center;
  }
}
@media (max-width: 550px) {
  .ABC {
    display: flex;
    min-height: 120vh;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  .heading {
    color: rgb(255, 255, 255);
    font-weight: bold;
    text-align: center;
    padding: 0;
    -webkit-transform: translateY(-250px);
            transform: translateY(-250px);
    box-sizing: content-box;
  }
  #button {
    margin: auto;
  }

  .footerContent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap-reverse;
    align-items: center;
    padding: 20px 30px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 4;
    width: 100vw;
    max-height: 100vh;
  }
  .Nairobi {
    max-height: 29vh;
    position: absolute;
    bottom: 10%;
    right: 0;
    z-index: 1;
    display: none;
  }

  .footerContent .left {
    width: 100vw;
    color: white;
  }

  .footerContent .left .RClogo {
    width: 250px;
    object-fit: contain;
  }

  .footerContent .right {
    color: #fff;
    width: 100vw;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .footerContent .right ul li {
    color: #fff;
    align-items: center;
    text-align: center;
    -webkit-transform: translateX(-20px);
            transform: translateX(-20px);
  }
}

@media (max-height: 600px) {
  .ABC {
    min-height: 120vh;
  }

  .Nairobi {
    max-height: 50vh;
    position: absolute;
    bottom: 35%;
    right: 0;
    z-index: 1;
  }
}

