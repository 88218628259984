.back {
  background-image: "spot3.png";
  background-color: #000;
  min-height: 100vh;
}
.clear {
  clear: both;
}

.layer {
  width: 100%;
}

.maskPhoto {
  height: 60vh;
  margin-left: 8%;
  top: 320px;
  float: left;
  object-fit: contain;
}
.material {
  color: antiquewhite;
  margin-left: 0%;
  margin-top: 3.5%;
  display: block;
  font-family: Poppins;
  text-align: start;
  font-style: normal;
  font-weight: normal;
  flex-wrap: wrap;
  line-height: 25px;
  margin-left: 20%;
  color: #ffffff;
  float: left;
}
.headingAbout {
  border-bottom: 5px solid var(--x-red);
  font-family: PurplePurse;
  font-style: normal;
  font-weight: bolder;
  font-size: var(--x-heading-size);
  line-height: 90px;
  color: #ffffff;
  max-width: max-content;
  text-shadow: 0.4rem 1rem 0.1rem rgba(0, 0, 0, 0.25);
  margin: auto;
}
.containerAbout {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 80px 50px;
  /* width: 50%;
  float: right;
  margin-right: 8%; */
}

@media (max-width: 1024px) {
  .maskPhoto {
    width: 80vw;
    margin-left: 0;
    float: center;
    opacity: 50%;
  }
  .material {
    color: antiquewhite;
    margin-top: 3.5%;
    display: block;
    font-family: Poppins;
    text-align: start;
    font-style: normal;
    font-weight: normal;
    flex-wrap: wrap;
    line-height: 25px;
    margin-left: 0;
    color: #ffffff;
    float: left;
    padding-bottom: 30px;
  }

  .headingAbout {
    border-bottom: 5px solid var(--x-red);
    font-family: PurplePurse;
    font-style: normal;
    font-weight: bolder;
    font-size: 2.3em;
    color: #ffffff;
    max-width: max-content;
    text-shadow: 0.4rem 1rem 0.1rem rgba(0, 0, 0, 0.25);
    margin: 0;
    margin-bottom: 20px;
  }
  .containerAbout {
    align-content: center;
    text-align: center;
    align-items: center;
    float: unset;
    display: block;
    margin: auto;
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .maskPhoto {
    width: 100vw;
    float: right;
    opacity: 50%;
    display: none;
  }
  .material {
    color: antiquewhite;
    margin-top: 3.5%;
    display: block;
    font-family: Poppins;
    text-align: start;
    font-style: normal;
    font-weight: normal;
    flex-wrap: wrap;
    line-height: 25px;
    margin-left: 0;
    color: #ffffff;
    float: left;
    padding-bottom: 30px;
  }

  .headingAbout {
    border-bottom: 5px solid var(--x-red);
    font-family: PurplePurse;
    font-style: normal;
    font-weight: bolder;
    font-size: 2.3em;
    color: #ffffff;
    max-width: max-content;
    text-shadow: 0.4rem 1rem 0.1rem rgba(0, 0, 0, 0.25);
    margin: 0;
    margin-bottom: 20px;
  }
  .containerAbout {
    align-content: center;
    text-align: center;
    align-items: center;
    float: unset;
    display: block;
    margin: auto;
    flex-wrap: wrap;
  }
}
