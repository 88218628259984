#caseBlanche {
  background-color: black;

  padding-top: 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.caseLoad {
  max-width: 100%;
  width: 20em;
  height: 20em;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: visible;
}
.img-load {
  animation: bouncy 2s linear infinite;
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 100;
}
.img-load > img {
  position: relative;
  width: 100%;
  height: auto;
  object-fit: contain;
}
#rond {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation: rond 2s linear infinite;
  border: 1px solid white;
}
#rond > #test {
  height: 8px;
  width: 8px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  left: 40%;
}
@keyframes rond {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rond {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

@keyframes bouncy {
  0% {
    transform: translateX(-50%) translateY(-100%);
  }

  50% {
    transform: translateX(-50%) translateY(50%);
  }

  100% {
    transform: translateX(-50%) translateY(-100%);
  }
}
