.ABC {
  position: relative;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: black;
  box-sizing: content-box;
}
.Nairobi {
  max-height: 65vh;
  position: absolute;
  bottom: 29%;
  right: 0;
  z-index: 1;
}

.wave {
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  width: 100%;
}

.heading {
  color: rgb(255, 255, 255);
  font-weight: bold;
  text-align: left;
  padding: 100px 0px 0px 50px;
  box-sizing: content-box;
}
.heading p {
  font-family: PurplePurse;
  font-style: normal;
  font-weight: bolder;
  font-size: var(--x-heading-size);
  line-height: 90px;
  color: #ffffff;
  max-width: max-content;
  text-shadow: 0.4rem 1rem 0.1rem rgba(0, 0, 0, 0.25);
}

.footerContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 4;
  width: 100vw;
  max-height: 100vh;
}

.footerContent .left {
  width: 500px;
  color: white;
}

.footerContent .left .RClogo {
  width: 300px;
  object-fit: contain;
}

.footerContent .right {
  color: #fff;
}
.footerContent .right ul {
  color: #fff;
  text-align: left;
  list-style: none;
}
#button {
  margin-top: 15px;
  border: 2px solid transparent;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  transition: all 0.3s linear;
  padding-block: 0.3em;
  background-color: var(--x-red);
  color: white;
  min-width: 12em;
}
#button:hover {
  opacity: 0.8;
}

@media (max-width: 1024px) {
  .ABC {
    min-height: 50vh;
  }

  .Nairobi {
    max-height: 40vh;
    position: absolute;
    bottom: 20%;
    right: 0;
    z-index: 1;
  }
}

@media (max-width: 768px) {
  .ABC {
    display: flex;
    min-height: 50vh;
    max-height: 100vh;
    flex-direction: column;
    justify-content: space-around;
  }
  .heading {
    color: rgb(255, 255, 255);
    font-weight: bold;
    text-align: left;
    padding: 0;
    transform: translateY(-200px);
    box-sizing: content-box;
    padding-left: 50px;
  }
  .footerContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 4;
    width: 100vw;
    max-height: 100vh;
  }
  .Nairobi {
    max-height: 40vh;
    position: absolute;
    bottom: 20%;
    right: 0;
    z-index: 1;
  }

  .footerContent .left {
    width: 45vw;
    color: white;
  }

  .footerContent .left .RClogo {
    width: 250px;
    object-fit: contain;
  }

  .footerContent .right {
    color: #fff;
    width: 45vw;
    align-items: center;
    text-align: center;
  }
  .footerContent .right ul li {
    color: #fff;
    align-items: center;
    text-align: center;
  }
}
@media (max-width: 550px) {
  .ABC {
    display: flex;
    min-height: 120vh;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  .heading {
    color: rgb(255, 255, 255);
    font-weight: bold;
    text-align: center;
    padding: 0;
    transform: translateY(-250px);
    box-sizing: content-box;
  }
  #button {
    margin: auto;
  }

  .footerContent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap-reverse;
    align-items: center;
    padding: 20px 30px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 4;
    width: 100vw;
    max-height: 100vh;
  }
  .Nairobi {
    max-height: 29vh;
    position: absolute;
    bottom: 10%;
    right: 0;
    z-index: 1;
    display: none;
  }

  .footerContent .left {
    width: 100vw;
    color: white;
  }

  .footerContent .left .RClogo {
    width: 250px;
    object-fit: contain;
  }

  .footerContent .right {
    color: #fff;
    width: 100vw;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .footerContent .right ul li {
    color: #fff;
    align-items: center;
    text-align: center;
    transform: translateX(-20px);
  }
}

@media (max-height: 600px) {
  .ABC {
    min-height: 120vh;
  }

  .Nairobi {
    max-height: 50vh;
    position: absolute;
    bottom: 35%;
    right: 0;
    z-index: 1;
  }
}
