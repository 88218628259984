.building {
  width: 100%;
}
.Trackx {
  width: 100%;
  min-height: 100vh;
  background-color: #000;
  background-image: url("./bank.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  padding: 20px 0;
}
.Trackx .left {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 20%;
}
.Trackx .Right {
  position: absolute;
  width: 20%;
  top: 0%;
  right: 0%;
}

.Trackx .track_box {
  padding: 5%;
}

.Trackx .track_box .box_body {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: #fff;
  font-family: Poppins;
  font-style: normal;
  text-shadow: 0.4rem 1rem 0.1rem rgba(0, 0, 0, 0.25);
}
.Trackx .track_box .box_body .box {
  width: 50%;
  min-width: 300px;
  padding: 20px 5px;
}

.Trackx .track_box .box_body .box:hover {
  background-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.74),
    rgba(22, 1, 1, 0.767)
  );
  text-shadow: 0.4rem 1rem 0.1rem rgba(255, 255, 255, 0.048);
}
.Trackx .track_box .box_body .Agriculture,
.Trackx .track_box .box_body .Communication {
  border-bottom: 2px solid #abafb0;
  align-self: flex-end;
}

.Trackx .track_box .box_body .Healthcare,
.Trackx .track_box .box_body .Communication {
  border-right: 2px solid #abafb0;
}

.Trackx .track_box .box_body .Open {
  align-self: flex-end;
  border-top: 2px solid #abafb0;
  margin: auto;
}

.Trackx .Tracks > p {
  border-bottom: 5px solid var(--x-red);
  font-family: PurplePurse;
  font-style: normal;
  font-weight: bolder;
  font-size: var(--x-heading-size);
  text-transform: uppercase;
  color: #ffffff;
  text-shadow: 0.4rem 1rem 0.1rem rgba(0, 0, 0, 0.25);
  width: max-content;
  margin: 0 auto;
}

@media (max-width: 700px) {
  .Trackx {
    width: 100%;
    min-height: 100vh;
    background-color: #000;
    background-image: none;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    padding: 20px 0;
  }

  .Trackx .track_box .box_body {
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
  }
  .Trackx .track_box .box_body .Agriculture,
  .Trackx .track_box .box_body .Communication {
    align-self: center;
  }
  .Trackx .track_box .box_body .box {
    width: 80%;
    border-right: none;
    border-bottom: 2px solid #abafb0;
  }
}
