.App {
  text-align: center;
  overflow-x: hidden;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.discordCont {
  position: fixed;
  bottom: 10px;
  right: 10px;
  animation: comein 2s linear;
}
@keyframes comein {
  0% {
    bottom: -200px;
  }
  20% {
    bottom: 10px;
  }
  30% {
    right: 12px;
  }
  40% {
    right: 7px;
  }
  50% {
    right: 12px;
  }
  60% {
    right: 12px;
  }
  70% {
    right: 7px;
  }
  80% {
    right: 12px;
  }
  90% {
    right: 7px;
  }
  100% {
    right: 10px;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
