@-webkit-keyframes simgx {
  0%,
  100% {
    -webkit-transform: scale(1) rotate(0deg);
    transform: scale(1) rotate(0deg);
    opacity: 1;
  }
  20%,
  80% {
    -webkit-transform: scale(1.05) rotate(45deg);
    transform: scale(1.05) rotate(45deg);
    opacity: 0.8;
  }
  30%,
  70% {
    -webkit-transform: scale(1.1) rotate(90deg);
    transform: scale(1.1) rotate(90deg);
    opacity: 0.6;
  }
  40%,
  60% {
    -webkit-transform: scale(1.14) rotate(135deg);
    transform: scale(1.14) rotate(135deg);
    opacity: 0.5;
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    opacity: 0.4;
  }
}

@keyframes simgx {
  0%,
  100% {
    -webkit-transform: scale(1) rotate(0deg);
    transform: scale(1) rotate(0deg);
    opacity: 1;
  }
  20%,
  80% {
    -webkit-transform: scale(1.05) rotate(45deg);
    transform: scale(1.05) rotate(45deg);
    opacity: 0.8;
  }
  30%,
  70% {
    -webkit-transform: scale(1.1) rotate(90deg);
    transform: scale(1.1) rotate(90deg);
    opacity: 0.6;
  }
  40%,
  60% {
    -webkit-transform: scale(1.14) rotate(135deg);
    transform: scale(1.14) rotate(135deg);
    opacity: 0.5;
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    opacity: 0.4;
  }
}

@-webkit-keyframes danger_blinx {
  0%,
  50%,
  100% {
    -webkit-box-shadow: 5px 5px 100px #f00;
    box-shadow: 5px 5px 100px #f00;
  }
  25%,
  75% {
    -webkit-box-shadow: 5px 5px 10px #00000073, 0 30px 20px -10px #00000021;
    box-shadow: 5px 5px 10px #00000073, 0 30px 20px -10px #00000021;
  }
}

@keyframes danger_blinx {
  0%,
  50%,
  100% {
    -webkit-box-shadow: 5px 5px 100px #f00;
    box-shadow: 5px 5px 100px #f00;
  }
  25%,
  75% {
    -webkit-box-shadow: 5px 5px 10px #00000073, 0 30px 20px -10px #00000021;
    box-shadow: 5px 5px 10px #00000073, 0 30px 20px -10px #00000021;
  }
}

@-webkit-keyframes spox_move {
  0%,
  100%,
  20%,
  80% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  10%,
  90% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  33%,
  67% {
    -webkit-transform: translateY(-10px) rotateY(0deg);
    transform: translateY(-10px) rotateY(0deg);
  }
  50% {
    -webkit-transform: translateY(-10px) rotateY(180deg);
    transform: translateY(-10px) rotateY(180deg);
  }
}

@keyframes spox_move {
  0%,
  100%,
  20%,
  80% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  10%,
  90% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  33%,
  67% {
    -webkit-transform: translateY(-10px) rotateY(0deg);
    transform: translateY(-10px) rotateY(0deg);
  }
  50% {
    -webkit-transform: translateY(-10px) rotateY(180deg);
    transform: translateY(-10px) rotateY(180deg);
  }
}

.spox {
  max-width: 300px;
  width: 50vw;
  min-width: 140px;
  height: 135px;
  background: #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 10px;
  border: 2px solid #e60909;
  cursor: default;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  margin: 0 5px;
}

.spox:hover {
  background: #0000;
  border-color: #0000;
}

.spox:hover > .spox_logo > img {
  background: #ffffff;
  border-radius: 50%;
  -webkit-animation: spox_move 10s linear infinite;
  animation: spox_move 10s linear infinite;
  -webkit-box-shadow: 0px 20px 5px -10px #000;
  box-shadow: 0px 20px 5px -10px #000;
}

.spox > .spox_logo > img {
  background: #fcfcfc94;
  border-radius: 50%;
}

.spox > .spox_name {
  font-family: PurplePurse;
  letter-spacing: 1px;
  font-size: 1.2rem;
  color: #ffffff;
  font-weight: 500;
  text-shadow: 3px 3px 2px #000;
}

.sp_parent {
  background-color: #000;
  background-image: linear-gradient(
    45deg,
    #000 40%,
    rgba(0, 0, 0, 0.609),
    #aaaaaa3d,
    rgba(0, 0, 0, 0.609),
    #000 60%
  );
  color: #fff;
  -webkit-animation: bgx 20s linear infinite;
  animation: bgx 20s linear infinite;
  background-size: 400% 400%;
  overflow: hidden;
}

.sp_parent .sp_me .sp_big_child,
.sp_parent .sp_me .sp_small_child {
  height: 30vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  overflow: hidden;
}

.sp_parent .sp_me .sp_big_child .sp_name,
.sp_parent .sp_me .sp_small_child .sp_name {
  border-bottom: 5px solid #e60909;
  font-family: PurplePurse;
  font-style: normal;
  font-weight: bolder;
  font-size: 3.2em;
  line-height: 90px;
  color: #ffffff;
  text-shadow: 0.4rem 1rem 0.1rem rgba(0, 0, 0, 0.25);
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.sp_parent .sp_me .sp_big_child > img,
.sp_parent .sp_me .sp_small_child > img {
  -webkit-transform: translateY(-200%);
  transform: translateY(-200%);
  -webkit-transition: 1s;
  transition: 1s;
  opacity: 0.8;
}

.sp_parent .sp_me .sp_big_child:hover > img,
.sp_parent .sp_me .sp_small_child:hover > img {
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
}

.sp_parent .sp_me .sp_child {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.sp_parent .sp_me .sp_child .sp_child_fav_bag {
  width: 90vw;
  max-width: 800px;
  border-radius: 0 0 5px 5px;
  -webkit-box-shadow: 5px 5px 50px #000;
  box-shadow: 5px 5px 50px #000;
  background: #0000;
}

.sp_parent .sp_me .sp_child .sp_child_fav_bag:hover {
  border-color: #0000 #e60909 #e60909 #e60909;
  -webkit-animation: danger_blinx 2.6s linear infinite;
  animation: danger_blinx 2.6s linear infinite;
}

.sp_parent .sp_me .sp_child .sp_child_fav_bag .sp_child_fav_bag_zip_even {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 20px;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.sp_parent .sp_me .sp_child .sp_child_fav_bag .sp_child_fav_bag_zip_odd {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 20px;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
}

@media screen and (max-width: 768px) {
  .sp_parent .sp_me .sp_big_child,
  .sp_parent .sp_me .sp_small_child {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .sp_parent .sp_me .sp_big_child .sp_name,
  .sp_parent .sp_me .sp_small_child .sp_name {
    border-bottom: 5px solid #e60909;
    font-family: PurplePurse;
    font-style: normal;
    font-weight: bolder;
    font-size: 2.7em;
    line-height: 60px;
    color: #ffffff;
    text-shadow: 0.4rem 1rem 0.1rem rgba(0, 0, 0, 0.25);
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
}
@media screen and (max-width: 720px) {
  .sp_parent .sp_me .sp_big_child,
  .sp_parent .sp_me .sp_small_child {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
