@import url("https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Cabin+Sketch&family=Fredericka+the+Great&family=Indie+Flower&family=Kalam:wght@300&display=swap");
@-webkit-keyframes bgCircle {
  from {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
  }
}
@keyframes bgCircle {
  from {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
  }
}

@-webkit-keyframes proToggle {
  0% {
    right: 0px;
  }
  25%,
  50%,
  75% {
    right: 10px;
  }
  100% {
    right: 0px;
  }
}

@keyframes proToggle {
  0% {
    right: 0px;
  }
  25%,
  50%,
  75% {
    right: 10px;
  }
  100% {
    right: 0px;
  }
}

.faq_parent {
  background: #000;
  min-height: 100vh;
  color: #ccc;
  z-index: 0;
}

.faq_parent ::-webkit-scrollbar {
  width: 5px;
}

.faq_parent ::-webkit-scrollbar-track {
  background: #111111b4;
}

.faq_parent ::-webkit-scrollbar-thumb {
  background: var(--x-red);
}

.faq_parent ::-webkit-scrollbar-thumb:hover {
  background: var(--x-red);
}

.faq_parent .faq_bg_circle {
  width: 7vw;
  height: 7vw;
  position: absolute;
  z-index: 1;
  border-radius: 50%;
  background-image: radial-gradient(#fdfdfd1c, #faf9f91e, #fffbfb1a);
  filter: blur(8px);
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-animation: bgCircle 10s infinite;
  animation: bgCircle 10s infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.faq_parent .faq_blur_bg {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  z-index: 2;
}

.faq_parent .faq_me {
  z-index: 3;
  position: absolute;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
}

.faq_parent .faq_me .faq_heading {
  margin: 3% 0;
  border-bottom: 5px solid var(--x-red);
  font-family: PurplePurse;
  font-style: normal;
  font-weight: bolder;
  font-size: var(--x-heading-size);
  line-height: 90px;
  color: #ffffff;
  text-shadow: 0.4rem 1rem 0.1rem rgba(0, 0, 0, 0.25);
}

.faq_parent .faq_me .faq_container {
  border-radius: 25px;
  width: 90%;
  max-width: 1024px;
  min-height: 400px;
  background-color: rgba(26, 31, 34, 0.8);
  border: 1px solid #444444;
  -webkit-box-shadow: 20px 20px 20px #0c0c0c;
  box-shadow: 20px 20px 20px #0c0c0c;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 50px;
}

.faq_parent .faq_me .faq_container .faq_qns,
.faq_parent .faq_me .faq_container .faq_not_qns {
  height: 100%;
  width: 50%;
}

.faq_parent .faq_me .faq_container .faq_qns {
  padding: 30px;
  overflow-y: auto;
}

.faq_parent .faq_me .faq_container .faq_qns .faq_q {
  width: 100%;
  font-family: sans-serif;
  border: 1px solid var(--x-red);
  background-color: #000;
  border-radius: 10px;
  padding: 15px;
  margin-top: 10px;
  cursor: pointer;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.faq_parent .faq_me .faq_container .faq_qns .faq_q:hover {
  -webkit-transform: translateX(10px);
  transform: translateX(10px);
  background-color: #000;
  -webkit-box-shadow: -5px -5px 15px #000;
  box-shadow: -5px -5px 15px #000;
}

.faq_parent .faq_me .faq_container .faq_qns .faq_q:hover::before {
  content: ">> ";
}

.faq_parent .faq_me .faq_container .faq_not_qns {
  padding: 2.5% 5%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.faq_parent .faq_me .faq_container .faq_not_qns > img {
  width: 90%;
}

.faq_parent .faq_ans_screen {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background: #23232371;
  background-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(#0f0e0e71),
      to(#000000d0)
    ),
    url("https://wallpaperaccess.com/full/2626507.jpg");
  background-image: linear-gradient(0deg, #0f0e0e71, #000000d0),
    url("https://wallpaperaccess.com/full/2626507.jpg");
  z-index: 100;
  -webkit-transition: 0.8s;
  transition: 0.8s;
}

.faq_parent .faq_ans_screen .faq_ans_board {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 95%;
  height: 90%;
  overflow-y: auto;
  overflow-x: hidden;
  background: #0c130c;
  background-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(#0f0e0e34),
      to(#00000046)
    ),
    url("https://images.unsplash.com/photo-1578662996442-48f60103fc96?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YmxhY2tib2FyZHxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&w=1000&q=80");
  background-image: linear-gradient(0deg, #0f0e0e34, #00000046),
    url("https://images.unsplash.com/photo-1578662996442-48f60103fc96?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YmxhY2tib2FyZHxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&w=1000&q=80");
  z-index: 110;
  border: 10px solid #141414;
}

.faq_parent .faq_ans_screen .faq_ans_board .board_question {
  padding: 20px;
  font-size: 2rem;
  text-align: left;
  margin-bottom: 20px;
  font-family: "Fredericka the Great", cursive;
}

.faq_parent .faq_ans_screen .faq_ans_board .board_answer {
  padding: 20px;
  font-size: 1.3rem;
  text-align: left;
  font-family: "Kalam", cursive;
}

.faq_parent .faq_ans_screen .faq_ans_board .board_answer > p {
  text-indent: 20px;
}

.faq_parent .faq_ans_screen .faq_ans_professor {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 111;
}

.faq_parent .faq_ans_screen .faq_ans_professor > img {
  width: 70%;
  -webkit-transition: 0.8s;
  transition: 0.8s;
}

.faq_parent .faq_ans_screen .faq_ans_professor .professor_toggle {
  right: 10px;
  bottom: 10px;
  cursor: pointer;
  -webkit-animation: proToggle 2s infinite;
  animation: proToggle 2s infinite;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

@media (max-width: 720px) {
  .faq_parent .faq_me .faq_container .faq_qns {
    width: 100%;
  }
  .faq_parent .faq_me .faq_container .faq_not_qns {
    display: none;
  }

  .faq_container {
    margin-top: 10px;
    margin-bottom: 40px;
  }
}
