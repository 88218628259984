.Prizes {
  min-height: 100vh;
  max-height: max-content;
  background-color: #000;
  z-index: 0;
}

.Prizes .h_name {
  border-bottom: 5px solid var(--x-red);
  font-family: PurplePurse;
  font-style: normal;
  font-weight: bolder;
  font-size: var(--x-heading-size);
  text-transform: uppercase;
  line-height: 90px;
  color: #ffffff;
  text-shadow: 0.4rem 1rem 0.1rem rgba(0, 0, 0, 0.25);
  width: max-content;
  margin: auto;
}

.PrizesContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 90vh;
  justify-content: space-around;
  align-items: center;
  background: url("../Prizes/Money-Heist-Mask-PNG-Image\ 3.svg");
  background-color: #000;
  background-position: center;
  background-repeat: repeat-x;
}

.Prizes .PrizesContainer .cont1 img {
  width: 500px;
  height: 500px;
  object-fit: contain;
}

@media (max-width: 768px) {
  .Prizes {
    min-height: max-content;
  }
  .PrizesContainer {
    background-repeat: no-repeat;
  }

  .Prizes .PrizesContainer .cont1 img {
    padding-left: 25px;
    width: 100vw;
    height: 50vh;
    object-fit: contain;
  }
}
